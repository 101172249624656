.delimiter {
    height: 100%;
    width: 1px;
    background-color: #DEE1E5;
}

#toolbar {
    background-color: var(--primary-light);
    margin: auto;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 9px;
    align-items: center;
    font-family: 'Roboto', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI";
    font-size: 16px;
    padding: 12px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    border-radius: 16px;
    width: 420px;
    height: 65px;
    border: none;
    box-shadow: 0px 4px 8px 0px rgba(94, 126, 168, 0.25);
}

#toolbar button {
    padding: 0;
    color: black;
    height: 35px;
    width: 35px;
    padding-left: 2.5px;
}

#toolbar select {
    padding-left: 9px;
}
#toolbar .ql-active {
    background-color:#DEE1E5;
}
#toolbar button:hover {
    background-color: #DEE1E5;
}


.ql-container {
    position: relative;
    height: 500px;
    font-size: 24px;
    margin: 18px;
}

.ql-container.ql-snow {
    border: unset;
}

.ql-tooltip {
    border: unset;
    font-size: 16px;
    border-radius: 16px;
    padding: 14px !important;
}