.settings {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  width: 110%;
  height: 100%;
  background-color: #FFFDFA;
  transform: translateX(100%);
  transition: 0.4s ease-in-out;
  padding: 50px;
  box-shadow: 0px 2px 16px 0px rgba(94, 126, 168, 0.25);
}

.settings.visible {
  transform: translateX(-150px);
  visibility: visible;
}

.settings.hidden {
  transform: translateX(100%);
  visibility: hidden;
}

.title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 200px;
}

.title-container svg{
  fill: #8A929E;
}

.title-container .title {
  padding: 0;
  margin: 0;
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI";
  font-size: 36px;
  font-weight: 400;
  text-align: left;
  color: var(--primary-text);
}

.card {
  background: white;
  border-radius: 8px;
  padding: 20px 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 500px;
  height: 500px;
}

.content {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}