.layout {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.layout .opening {
    background-color: #383F47;
    flex: 1;
}

.layout .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
}