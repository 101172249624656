.header {
    /* position:fixed; */
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    width: stretch;
    background-color: var(--secondary-text);
    padding: 16px;
    justify-content: space-between;
    display: flex;
  }
  
.header-logo {
display: flex;
font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI";
font-size: 42px;
font-weight: 500;
line-height: 49.22px;
text-align: left;
color: var(--primary-light);
gap: 24px
}

.user {
font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI";
font-size: 18px;
font-weight: 400;
line-height: 24px;
text-align: left;
display: flex;
color: var(--secondary-light);
gap: 8px;
align-items: center
}

.user img {
width: 42px;
height: 42px;
border-radius: 50%;
object-fit: cover;
}
  