.basic {
    text-align: center;
    width: 100%;
    height: 100%;
}

.basic .title {
    font-weight: normal;
    font-size: 20px;
}

.basic .subtitle {
    font-weight: normal;
    font-size: 16px;
    
}

.basic .buttonContainer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 70px;
}

.basic .button {
    background-color: #383F47;
    padding: 12px;
    color: white;
}