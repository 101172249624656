.help {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    width: 110%;
    height: 100%;
    background-color: #FFFDFA;
    transform: translateX(100%);
    transition: 0.4s ease-in-out;
    padding: 50px;
    box-shadow: 0px 2px 16px 0px rgba(94, 126, 168, 0.25);
  }
  
  .help.visible {
    transform: translateX(-150px);
    visibility: visible;
  }
  
  .help.hidden {
    transform: translateX(100%);
    visibility: hidden;
  }
  
  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    width: 120px;
  }
  
  .title-container svg{
    fill: #8A929E;
  }
  
  .title-container .title {
    padding: 0;
    margin: 0;
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI";
    font-size: 36px;
    font-weight: 400;
    text-align: left;
    color: var(--primary-text);
  }
  
  .content {
    text-align: left;
    margin-top: 28px;
    overflow: auto;
    height: 70%;
  }

  .custom-line {
    width: 400px;
    margin: 30px 0
  }
