:root {
  --primary-text: #1C1C25;
  --secondary-text: #383F47;
  --disabled-text: #8A929E;
  --primary-light: #FBFCFC;
  --secondary-light: #FFFCF7;
  --primary-link: #254775;
  --nav-link: #5E7EA8;
  --selected-link: #E6ECF5;
}

#root {
  height: 100vh;
  overflow: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #FFFDFA;
}

.main-content {
  display: flex;
  flex-direction: row;
  height: stretch;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #FFFDFA;
  width: 100%;
}

button {
  cursor: pointer;
}

a {
   cursor: pointer;
}
