.side {
    background-color: var(--primary-light);
    padding: 16px 4px 16px 0;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 16px 0px rgba(94, 126, 168, 0.25);
    width: 137px;
    height:88%;  
    justify-content: space-between;
    z-index: 2;
  }
  
  .side-menu {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }