.nav-link {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;
    color: var(--nav-link);
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  
  .nav-link svg {
    width: 24px;
    height: 24px;
    fill: var(--nav-link);
  }
  
  .nav-link.selected, .nav-link:hover, .nav-link.selected svg, .nav-link:hover svg {
    border-radius: 0px 4px 4px 0px;
    background-color: var(--selected-link);
    color: var(--primary-link);
    fill: var(--primary-link);
  }