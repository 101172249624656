.static-note {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    max-width: 400px;
    background-color: #383F47;
}
.static-note .title { 
    color: white;
    margin-bottom: 15px;
    letter-spacing: .7px
}
.static-note .text { 
    color: white;
    letter-spacing: .7px;
    margin-bottom: 20px;
}

.static-note .custom-btn {
    background-color: #565c63;
    margin-top: 20px;
}

.static-note .custom-btn:hover {
    transform: scale(1.05);
}