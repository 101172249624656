.btn-container {
    background-color: #383F47;
    color: white;
    border: 0;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 1px;
    transition:  300ms ease;
}



.btn-container:hover {
    transform: scale(1.1);
}

.btn-container.light {
    background-color: white;
    color: #383F47;
    border: 1px solid #383F47;
}

.disabled {
    pointer-events: none;
    background-color: gray;
}
