.separate-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
}

.separate-container .horizontal-line {
    background-color: rgb(156, 156, 156);
    height: 1px;
    width: 50px;
}

.separate-container .text {
    font-weight: bold;
    margin: 0 5px;
}