.input-container {
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI";
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 35px;
    width: 100%;
}


.input-container label {
    position: absolute;
    transform: translate(5px, 8px);
    transition: 150ms ease-in-out;
    font-size: 14px;
    pointer-events: none;
}

.input-container .animated {
    transform: translate(0px, -20px);
}

.input-container input {
    border-radius: 6px;
    height: 30px;
    font-size: 16px;
}

.input-container .error-input {
    border: 2px solid red;
}

.input-container .error {
    position: absolute;
    color: red;
    font-size: 12px;
    transform: translateY(32px);
}

.input-container .info-icon {
    position: absolute;
    cursor: pointer;
    right: 5px;
    top: 7px;
    width: 22px;

}

.info-icon:hover + .bubble {
    opacity: 1;
}

.bubble {
    position: absolute;
    bottom: 35px;
    left: 200px;
    height: auto;
    width: 150px;
    background-color: #F7494A;
    border-radius: 15px;
    padding: 8px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.bubble span{
    font-size: 14px;
    color: white;

}
