.date-picker-container {
    display: flex;
    justify-content: center;
    height:stretch;  
    background-color: var(--primary-light);
    box-shadow: 0px 2px 16px 0px rgba(94, 126, 168, 0.25);
    min-width: 150px;

    z-index: 1;
    height: 91%;
}

.date-picker {
display: flex;
flex-direction: column;
position: relative;
padding: 16px 24px;

width: fit-content;
gap: 16px;
z-index: 1;
color: var(--disabled-text);
-ms-overflow-style: none; 
overflow: hidden;
height: 300px;

}

.date-picker-content {
position: relative;
display: flex;
flex-direction: column;
gap: 16px;
overflow-y: scroll;
-ms-overflow-style: none; 
height: 300px;
}

.date-picker::before {
content: "";
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 30%;
background: linear-gradient(var(--primary-light), rgba(255, 255, 255, 0));
pointer-events: none;
z-index: 3;
}

.date-picker::after {
content: "";
position: absolute;
bottom: 0;
left: 0;
width: 100%;
height: 30%;
background: linear-gradient(rgba(255, 255, 255, 0), var(--primary-light));
pointer-events: none;
z-index: 3;
}

.date-picker::-webkit-scrollbar, .date-picker-content::-webkit-scrollbar {
display: none;
}

.date-picker-item.today {
    font-weight: 600;
}
  
.date-picker-item.selected, .date-picker-item:hover {
color: var(--secondary-text);
background-color: #DEE1E5;
}

.date-picker-item {
font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI";
font-size: 16px;
font-weight: 400;
line-height: 18.75px;
width: fit-content;
text-align: center;
color: var(--disabled-text);
cursor: pointer;
padding: 4px 8px 4px 8px;
border-radius: 4px;
margin: 0 auto;
}