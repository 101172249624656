body {
  height:100vh;
  margin: 0;
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI";
}

h1 {
  margin: 0;
  padding: 0;
}

button {
  background-color: transparent;
  color: white;
  border: 0;
  padding: 0;
}