.profile-settings {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.title {
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI";
    font-size: 28px;
    font-weight: 400;
    text-align: left;
    color: var(--primary-text);
}

.display-name-component {
    margin-top: 32px;
}

.display-name-component .custom-input {
    width: 200px;
}

.container {
    display: flex;
    justify-content: flex-start;
    margin-top: auto;
}
.action-buttons-container {
    display: flex;
    gap: 16px;
    width: 100%;
    margin-top: 16px;
}

.action-buttons-container .action-custom-button {
    border: 1px solid #cf0101 !important;
    color:#cf0101 !important;
}