.sign-in {
    border: 0;
    outline: none;    
}

.sign-in form {
    padding: 30px;
    width: 250px;
}

.additional-text {
    font-size: 14px;
}

.additional-text .link {
    color: #207FF7;
}

.error {
    font-weight: bold;
    color: #FF3333;
}