.forgot-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: none;    
}

.forgot-container .desc {
    max-width: 320px;
    margin-bottom: 0;
}

.forgot-container form {
    padding: 30px;
    width: 250px;
}

.additional-text-forgot {
    margin: 0;
}

.additional-text .link {
    color: #207FF7;
}