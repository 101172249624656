.sign-up {
    border: 0;
    outline: none;    
}

.sign-up form {
    padding: 30px;
    width: 250px;
}

.additional-text {
    font-size: 14px;
}

.additional-text .link {
    color: #207FF7;
}

.sign-up .error-container {
    background-color: rgb(221, 20, 20);
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.sign-up .error-container .error { 
    color: white;
}


.sign-up .horizontal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: rgb(156, 156, 156);
    height: 1px;
    width: 80px;
}

.sign-up .additional-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error {
    font-weight: bold;
    color: #FF3333;
}