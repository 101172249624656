.daily {
    padding: 32px;
}

.date {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* padding-left: 28px; */
}

.date::after {
    flex: 1;
    content: '';
}

.date h1 {
    padding: 0;
    margin: 0;
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI";
    font-size: 36px;
    font-weight: 400;
    line-height: 42.19px;
    text-align: left;
    color: var(--primary-text);
}

.date-controls {
    flex: 1;
    display: flex;
    gap: 24px;
    align-items: center;
}

.date-control svg {
    cursor: pointer;
    fill: var(--disabled-text);
}

.date-control:hover svg {
    fill: var(--secondary-text);
}
