.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 20px;
    z-index: 1000;
    width: 400px;
    height: 200px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .modal .close-icon {
    margin: 12px 0 0 12px;
  }
  
  .modal .close-icon img {
    width: 20px;
  }
  
  .modal .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
  }
  
  .fadeIn {
    opacity: 1;
  }
  
  .fadeOut {
    opacity: 0;
  }
  
  .slideIn {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  
  .slideOut {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.95);
  }
  